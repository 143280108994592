@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body{
  background-color: #282c34;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100vw;
}
.App {
  overflow-x: hidden;
  text-align: center;
  color: white;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

a {
  color: #61dafb;
}

h1, h2, h3, h4, h5, h6, p {
  color: white;
}

@media only screen 
  and (max-width: 480px) { 
      .List {
        font-size: 2px;
      }
    }


